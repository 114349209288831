import './App.css';
import { Suspense, lazy } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import PrivateComponent from './private/PrivateComponent';

export const Loadable = (Component) => (props) => {
  return (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
};

const Login = Loadable(lazy(() => import('./pages/Auth/Login')));
const MastercardLogin = Loadable(lazy(() => import('./pages/Auth/LoginMastercard')));
const FrenchChamberLogin = Loadable(lazy(() => import('./pages/Auth/LoginFrenchChamber')));
const Signup = Loadable(lazy(() => import('./pages/Auth/Signup')));
const ForgotPassword = Loadable(lazy(() => import('./pages/Auth/ForgotPassword')));
const ChangePassword = Loadable(lazy(() => import('./pages/Auth/ChangePassword')));
const AuthSubscription = Loadable(lazy(() => import('./pages/Auth/AuthSubscription')));
const Search = Loadable(lazy(() => import('./pages/Search')));
const SearchCategory = Loadable(lazy(() => import('./pages/SearchCategory')));
const Home = Loadable(lazy(() => import('./pages/Home')));
const Compare = Loadable(lazy(() => import('./pages/Compare')));
const MarketIntelligence = Loadable(lazy(() => import('./pages/MarketIntelligence')));
const UpdateUser = Loadable(lazy(() => import('./pages/UpdateUser')));
const Error = Loadable(lazy(() => import('./pages/error/Error')));
const TermsAndCondition = Loadable(lazy(() => import('./pages/Auth/TermsAndCondition')));
const SuccessPage = Loadable(lazy(() => import('./pages/Auth/SuccessPage')));
const LandingPage = Loadable(lazy(() => import('./pages/Landing/LandingPage')));
const Aboutus = Loadable(lazy(() => import('./pages/Landing/Aboutus')));
const Contactus = Loadable(lazy(() => import('./pages/Landing/Contactus')));
const SMSTarget = Loadable(lazy(() => import('./pages/SmsTarget')));
const SMSCampaign = Loadable(lazy(() => import('./pages/SmsCampaign')));

function App() {
  return (
    <>
      <BrowserRouter basename={'/web'}>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/login-mastercard' element={<MastercardLogin />} />
          <Route path='/login-fcck' element={<FrenchChamberLogin />} />
          <Route path='/' element={<LandingPage />} />
          <Route path='/about-us' element={<Aboutus />} />
          <Route path='/contact-us' element={<Contactus />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/change-password/:token' element={<ChangePassword />} />
          {/* <Route path='/auth-subscription' element={<AuthSubscription />} /> */}
          {/* <Route path='/terms-and-condition' element={<TermsAndCondition />} /> */}
          {/* <Route path="/payment-success/:id" element={<SuccessPage />} /> */}
          <Route path='*' element={<Error />} />

          <Route element={<PrivateComponent />}>
            <Route path="/search" element={<Search />} />
            <Route path="/search-category" element={<SearchCategory />} />
            <Route path="/home" element={<Home />} />
            <Route path="/comparison" element={<Compare />} />
            <Route path="/sms-target" element={<SMSTarget />} />
            <Route path="/sms-campaign" element={<SMSCampaign />} />
            <Route path="/market-intelligence" element={<MarketIntelligence />} />
            <Route path="/profile" element={<UpdateUser />} />
            <Route path='*' element={<Error />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;